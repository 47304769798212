import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function SessionProvider({ children }) {
  // Import necessary hooks,functions and initialize state variable
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Function to manage session
  const sessionManagement = useCallback(async () => {
    let token = searchParams.get("accessToken");
    let shop = searchParams.get("shop");
    if (token) {
      localStorage.setItem("accessToken", token);
      localStorage.setItem("shop", shop);
      searchParams.delete("accessToken");
      searchParams.delete("shop");
      setSearchParams(searchParams);
      navigate("/installation-guide");
    } else {
      if (!localStorage.getItem("accessToken")) {
        // window.location.href = `${process.env.REACT_APP_SHOPIFY_REDIRECT_URL}`;
      }
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    sessionManagement();
  }, [sessionManagement]);

  return children;
}
