import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import "./styles/global.scss";

import Layout from "./components/Layout";
import Loader from "./components/Loader";
import { HeartIcon } from "./components/icons";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Settings = React.lazy(() => import("./pages/Settings"));
const InstallationGuide = React.lazy(() => import("./pages/InstallationGuide"));
// const Support = React.lazy(() => import("./pages/Support"));
const WishlistUsers = React.lazy(() => import("./pages/WishlistUser"));
const WishlistProducts = React.lazy(() => import("./pages/WishlistProduct"));
const GeneralSettings = React.lazy(() => import("./pages/GeneralSetting"));
const UISettings = React.lazy(() => import("./pages/UISettings"));

function App() {
  return (
    <Layout>
      <ToastContainer />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/settings" element={<Settings />}>
            <Route path="ui-settings" element={<UISettings />} />
            <Route path="general-settings" element={<GeneralSettings />} />
            <Route
              path="*"
              index
              element={<Navigate replace to="/settings/ui-settings" />}
            />
          </Route>
          <Route
            path="/installation-guide"
            element={<InstallationGuide />}
            initial
          ></Route>
          {/* <Route path="/support" element={<Support />}></Route> */}
          <Route path="/wishlist-users" element={<WishlistUsers />}></Route>
          <Route
            path="/wishlist-products"
            element={<WishlistProducts />}
          ></Route>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="*" element={<Dashboard />}></Route>
        </Routes>
      </Suspense>
    </Layout>
  );
}

export default App;
