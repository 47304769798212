import React from "react";
import { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import "../styles/app.scss";
import Loader from "./Loader";
import { useSelector } from "react-redux";
import { designSelector } from "../selectors/designSlice.selector";
import { useIsFetching } from "@tanstack/react-query";

const Layout = ({ children }) => {
  // Import necessary hooks,functions and initialize state variable
  const isFetching = useIsFetching();
  const { isSidebarClosed } = useSelector(designSelector);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const mainBodyStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    filter: "blur(5px)",
    pointerEvents: "none",
  };
  useEffect(() => {
    // Function to update windowWidth when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add an event listener for the 'resize' event on the window
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmallScreen = windowWidth < 550;
  const isLoading = isFetching === 1;
  return (
    <div>
      {isLoading && <Loader />}
      <div
        className={
          isSmallScreen || isSidebarClosed ? "ya-main-after" : "ya-main"
        }
      >
        <Sidebar />
        <div className="ya-body " style={isLoading ? mainBodyStyle : {}}>
          <main>{children}</main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
