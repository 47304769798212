/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from "react";
import { useState, useEffect } from "react";
import logo from "../images/Logo.png";
import logout from "../images/logout.svg";
import { Link, NavLink } from "react-router-dom";
import "../styles/sidebar.scss";
import { useSelector } from "react-redux";
import { designSelector } from "../selectors/designSlice.selector";
import { useTranslation } from "react-i18next";
import {
  DashboardIcon,
  InstallationGuideIcon,
  ProductsIcon,
  SettingIcon,
  SupportIcon,
  UsersIcon,
} from "./icons";
import { cn } from "../lib/utils";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getLanguageList, changeLanguage } from "../services/language.service";
import { useNavigate } from "react-router";

const Sidebar = () => {
  // Import necessary hooks,functions and initialize state variable
  const navigate = useNavigate();
  const { t } = useTranslation(["translations", "translationsSetting"]);
  const { isSidebarClosed } = useSelector(designSelector);
  // const location = useLocation();
  // const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const selectedLanguage = localStorage.getItem("lang") || "English";
  /*
   * Toggles the state of the sidebar between open and closed.
   * Dispatches an action to update the Redux store with the new sidebar state.
   */

  // const toggleSidebar = () => {
  // dispatch(IS_SIDEBAR_CLOSED(!isSidebarClosed))
  // }

  useEffect(() => {
    // Function to update windowWidth when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add an event listener for the 'resize' event on the window
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  // const isSmallScreen = windowWidth < 550;

  // Function to toggle the sidebar if it's a small screen.
  const closeSideBar = () => {
    // if(isSmallScreen) {
    // toggleSidebar();
    // }
  };

  // Function to clear variables from localStorage
  const logOut = () => {
    localStorage.clear();
    // eslint-disable-next-line no-undef
    window.location.href = process.env.REACT_APP_SHOPIFY_REDIRECT_URL;
  };

  // array which is used in sidebar
  const pathArray = [
    {
      to: "/",
      Icon: DashboardIcon,
      name: "common.dashboard",
    },
    {
      to: "/settings",
      Icon: SettingIcon,
      name: "common.settings",
    },
    {
      to: "/installation-guide",
      Icon: InstallationGuideIcon,
      name: "common.installation-guide",
    },
    {
      to: "/wishlist-users",
      Icon: UsersIcon,
      name: "common.wishlist-users",
    },
    {
      to: "/wishlist-products",
      Icon: ProductsIcon,
      name: "common.wishlist-products",
    },
  ];

  const { data: languageList } = useQuery({
    queryKey: ["language-list"],
    queryFn: () => getLanguageList(),
  });

  const { mutate: changeLanguageFromServer } = useMutation({
    mutationKey: ["change-language"],
    mutationFn: (payload) => changeLanguage(payload),
    onSuccess: (data) => {
      navigate(0);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <div
      className={cn(
        "sidebar-main",
        "fixed p-[60px_23px_100px_50px] w-[300px] h-screen flex flex-col justify-between overflow-hidden top-0 left-0 bg-black",
        isSidebarClosed ? "collapsed" : "",
      )}
    >
      <div className="top-menu">
        <Link
          to="/"
          className="w-[153px] h-[34px] relative overflow-hidden mb-[54px] block m-auto"
          onClick={closeSideBar}
        >
          <div className="flex gap-3 items-center h-auto">
            <img
              src={logo}
              alt="sidebar-icon"
              className="object-contain h-[1.28rem]"
            ></img>

            <select
              className="w-10 py-[0.18rem] rounded-sm text-[#94A3B8] bg-[#F2F4F5] cursor-pointer z-50 truncate"
              onChange={(e) => {
                changeLanguageFromServer(e.target.value);
                localStorage.setItem("lang", e.target.value);
              }}
              onClick={(e) => {
                // Stop event propagation to prevent navigation
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {languageList?.data?.length > 0 &&
                languageList?.data.map((item, index) => (
                  <option
                    key={`__language_${item.language}__${index}`}
                    value={item.language}
                    selected={selectedLanguage === item.language}
                    className="truncate"
                  >
                    {item.language}
                  </option>
                ))}
            </select>
          </div>
        </Link>
        <div className="app-info-wrapper mt-4">
          <div className="app-info">{t("common.wishlist")}</div>
        </div>
        <div className="relative mt-5 flex flex-col gap-2 flex-grow">
          {pathArray.map(({ Icon, ...item }, index) => (
            <NavLink
              key={`__sidebar_${item.name}`}
              className={({ isActive }) =>
                cn(
                  "text-sm text-white font-semibold flex items-center gap-3 transition-all duration-300 ease-in-out [&>svg]:fill-white",
                  isActive
                    ? "bg-primary px-4 py-2.5 rounded-xl text-black [&>svg]:fill-black"
                    : "hover:text-primary py-2.5 rounded-xl [&>svg]:hover:fill-primary",
                )
              }
              onClick={closeSideBar}
              {...item}
            >
              <Icon className="h-7 w-7" />
              {t(item?.name)}
            </NavLink>
          ))}
        </div>
      </div>
      <Link className="bottom-menu" onClick={logOut}>
        <figure className="w-[30px] h-[30px] mb-0 mr-[20px] overflow-hidden">
          <img src={logout} alt="sidebar-icon"></img>
        </figure>
        {t("common.logout")}
        <span className="w-[23px] h-[5px] rounded-[32px] bg-pink-glow ml-[8px]"></span>
      </Link>
    </div>
  );
};

export default Sidebar;
