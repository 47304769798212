import "../styles/loader.scss";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="absolute z-[9999] top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4">
        <img src="/yellos-loading.gif" alt="" className="w-[80px] h-[80px]" />
      </div>
    </div>
  );
};
// 1
export default Loader;
